<template>
  <div v-if="user && user.id" class="users-info-chip-container">
    <v-row no-gutters align="center">
      <v-col cols="auto">
        <v-avatar v-if="user.avatar_url" size="30" class="mr-2 my-auto">
          <v-img :src="user.avatar_url" height="100%" width="100%"></v-img>
        </v-avatar>
      </v-col>
      <v-col
        cols="auto"
        :class="
          smAndDown
            ? 'd-flex align-start justify-center flex-col'
            : 'd-flex align-start justify-center'
        "
      >
        <span class="font-weight-bold user-info-name my-auto">
          {{ user.name }}
        </span>
        <span
          v-if="!hideUsername"
          class="text-caption text-grey user-info-username my-auto"
        >
          @{{ user.username }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import { useDisplay } from "vuetify";
import type { User } from "@/types/payload-types";
const { smAndDown } = useDisplay();
defineProps({
  user: {
    type: Object as PropType<User>,
    required: true,
  },
  hideUsername: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});
</script>
<style scoped lang="scss">
.users-info-chip-container {
  .user-info-name {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: -5px;
  }
}
</style>
